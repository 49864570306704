import { createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../../../api/kyc/api";
import { AsyncThunkActionType } from "../../store";
import { AsyncThunkDataType } from "../../common";
import { Components, Paths } from "../../../api/kyc/generated/client";

type DocsResponse = Components.Schemas.IndividualDocumentEntry[];

export const individualDocsGet: AsyncThunkActionType<
  DocsResponse,
  AsyncThunkDataType<null, null>
> = createAsyncThunk("individualDocsGet", async (data, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;
  try {
    const response = await api.IndividualDocuments();
    return response.data as DocsResponse;
  } catch (err: any) {
    return rejectWithValue(err.response.data);
  }
});
