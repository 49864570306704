import * as Yup from 'yup'
import { FC, useEffect, useState } from 'react'
import _ from 'lodash';
import { Row, Col, Divider, Select, Card, Button, message } from 'antd'
import Text, { TextProps } from 'antd/es/typography/Text'
import { DownOutlined, InboxOutlined, UploadOutlined } from '@ant-design/icons'
import { countries } from 'countries-list'
import { dispatch } from '../../../store/store'
import { authorizedPersonDocsGet } from '../../../store/kyc-front/asyncThunks/authorizedPersonDocsGet'
import { themeColor } from '../../../styles/themeStyles'
import { useSelector } from '../../../hooks/useSelector'
import { useActionStatus } from '../../../hooks/useActionStatus'
import { signatoriesUpdate } from '../../../store/kyc-front/asyncThunks/signatoriesUpdate'
import { authorizedPersonsGet } from '../../../store/kyc-front/asyncThunks/authorizedPersonsGet'
import { profileGet } from '../../../store/kyc-front/asyncThunks/profileGet'
import { signatoriesUpdateClear } from '../../../store/kyc-front/slices/signatoriesSlice'
import Title from 'antd/es/typography/Title'
import styled from 'styled-components'
import FormSelect from '../ui-elements/FormSelect'
import KycNextSteps from '../KycNextSteps'
import { FormCheckboxRadio } from '../ui-elements/FormCheckboxRadio'
import BasicFormWrapper from '../ui-elements/BasicForm'
import { ButtonUpper } from '../ui-elements/Buttons'
import KycDocsInfo from '../KycDocsInfo'

interface KProps {
    printRender?: boolean,
}


const Muted = styled.p`
    color: ${themeColor.gray}
`

const Signature = styled.div`
    border: 1px solid ${themeColor.grayLight};
    padding: 5px 10px;
    height: 100px;
    min-width: 200px;
    margin-top: 15px;
    margin-right: 15px;
    text-align: center;
`

const KycDataValidationForm: FC<KProps> = ({printRender}) => {
	const { Option } = Select;
    const company = useSelector((state) => state.user.company);
    const signatories = useSelector((state) => state.user.signatories);
    const {kycApplication} = useSelector((state) => state.user2.kycApplication)
    const envs = useSelector((state) => state.general.envs);
    const authorizedPersons = useSelector((state) => state.user.authorizedPersons);
    const initialValues = { 
        signatories: kycApplication.values.authorizedPersons?.filter((person: any) => person.isSignatory === 'true').map((person: any) => person.id).join(',')
    }
    const validation = Yup.object({
        signatories: Yup.string().required()
    });
    const signatoriesList = kycApplication.values.authorizedPersons?.filter((person: any) => person.isSignatory === 'true');
	return (
        <BasicFormWrapper id='dataValidationForm' initial={initialValues} validation={validation} dispatchSubmit={signatoriesUpdate} params={{companyId: envs.profileId}}  dispatchClear={signatoriesUpdateClear} dispatchGet={profileGet} successCondition={signatories.updatedSignatories?.Right?.value == true ? 'success' : ''} finalSubmit={true}>
            {({handleInputChange, onSelectChange, onDateChange, values, disabled}) => {
                const onSignatoryChange = (el: any) => {
                    onSelectChange(el, 'signatories');
                    dispatch(signatoriesUpdate({params: {companyId: envs.profileId}, data: {signatories: el.join()}}));
                }
                return (
                    <>
                        <div>
                            
                           
                            <p>1.  The funds that are and will be used to fund the transactions are clear and are not derived from any illegal activities but are derived from above mentioned source(s).</p>

                            <p>2.  We also recognize that the company may be subject to tax where tax resident and we hereby undertake to obtain advice as necessary to ensure that the due taxes are paid and statutory compliance is honored. To this effect, Bitclear AG accepts no liability for any tax consequences.</p>

                            <p>3.  We hereby declare that the details furnished above are true and correct to the best of our knowledge and belief and <b>we undertake to inform you of any changes therein, immediately by sending an email to KYC@bitclear.li</b>. In case any of the above information is found to be false or untrue or misleading or misrepresenting, we are aware that we may be held liable for it.</p>

                            <Muted>The EU General Data Protection Regulation (GDPR), which came into force on 25th May 2018, is effective for all EU and EEA residents in respect of their personal data which is collected and processed. The Privacy Policy statement of Bitclear AG in respect of the processing of clients’ data and the rights of clients is available on www.bitclear.li/privacy-policy. Please contact KYC@bitclear.li for any data protection matters.</Muted>
                            
                            {printRender ? (
                                <>
                                    <Text>Signatures of authorized persons:</Text>
                                    <Row>
                                        {signatoriesList?.map((ap: any, i: number) => {
                                            return <Col><Signature key={'signature' + i}><span>{ap.firstName ? ap.firstName : 'New'} {ap.lastName ? ap.lastName : 'Person'}</span></Signature></Col>
                                        })}
                                    </Row>
                                </>
                            ) : (
                                <>
                                    <KycNextSteps />
                                    <br />
                                    <Row gutter={30}>
                                        <Col md={24}>
                                            <FormSelect defaultValue={values.signatories !== '' ? values.signatories?.split(',') : values.signatories} mode="multiple" label='Who will be signing the documents? *' id='signatories' placeholder='Select at least one authorized person' onChange={(val) => onSignatoryChange(val)} disabled={disabled} type='company' >
                                                {authorizedPersons?.authorizedPersons.map((ap, i) => {
                                                    if(ap.roleType && ap.roleType === 'Director') {
                                                        return <Option key={'signatories' + i} value={ap.authorizedPersonId}>{ap.firstName ? ap.firstName : 'New'} {ap.lastName ? ap.lastName : 'Person'}</Option>
                                                    }
                                                    
                                                })}
                                            </FormSelect>
                                        </Col>
                                    </Row>
                                    <br />
                                    <Muted>(Signatory must be authorized to sign for the company)</Muted>
                                </>
                            )}
                        </div>
                    </>
                )
            }}
        </BasicFormWrapper>
    )
}

export default KycDataValidationForm
