import { createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../../../api/kyc/api";
import { AsyncThunkActionType } from "../../store";
import { AsyncThunkDataType } from "../../common";
import { IndividualBasicResponse } from "../slices/individualSlice";
import { Components, Paths } from "../../../api/kyc/generated/client";

type UpdateIndividualRequest = Components.Schemas.UpdateIndividual;

export const individualUpdate: AsyncThunkActionType<
  IndividualBasicResponse,
  AsyncThunkDataType<null, UpdateIndividualRequest, "data">
> = createAsyncThunk(
  "individualUpdate",
  async ({ params, data, config }, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await api.UpdateIndividual(null, data, config);
      return response.data as IndividualBasicResponse;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);
