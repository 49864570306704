import { createSlice } from "@reduxjs/toolkit"
import { kycApplicationApprovalsGet } from "store/kyc-backend/asyncThunks/kycApplicationApprovalsGet"
import { kycApplicationApprovalsUpdate } from "store/kyc-backend/asyncThunks/kycApplicationApprovalsUpdate"
import { kycApplicationFinalApprove } from "store/kyc-backend/asyncThunks/kycApplicationFinalApprove"
import { CaseReducerType } from "../../store"
import { APSteps, UboSteps, Step } from 'stepsScheme'
import { Paths } from "api/kyc/generated/client2"
import { kycApplicationSendBack } from "../asyncThunks/kycApplicationSendBack"
import { string } from "yup"



type State = {
    kycApplicationApprovals: any
    approvalErrors: any[]
    approvalsUpdated: any
    multiApprove: boolean
    finalApprove: boolean
    sentBack: boolean
}

interface ValidationError {
    isApprovalRequirementSatisfied: boolean
    approvedBy: string[]
    ref: string
    slug: string
}
interface DocValidationError {
    approvals: [{ref: string, slug: string, isApprovalRequirementSatisfied: boolean, approvedBy: string[]}],
    id: string
}

interface StepErrors {
	step: number
    substep?: number
    link: string
    name: string
	validationErrors: ValidationError[]
    docErrors: DocValidationError[]
    refId?: string
}

const initialState: State = {
    kycApplicationApprovals: {},
    approvalsUpdated: {},
    multiApprove: false,
    approvalErrors: [],
    finalApprove: false,
    sentBack: false
}

const parseApprovalErrors = (steps: any, elem: any) => {
    let payloadEl: StepErrors[] = []
    let data = elem?.payload?.data
    steps?.forEach((step: any) => {
        const stepFields = step.type === 'authorizedPersons' ? APSteps.flatMap(step => step.fields) : step.type === 'beneficialOwners' ? UboSteps.flatMap(step => step.fields) : step.fields || []
        const personsRefList = step.ref === 'ap' ? data?.authorizedPersons : step.ref === 'ubo' ? data?.beneficialOwners : data
        const refList = step.refId && personsRefList?.find((el: any) => el.id === step.refId)
        const validationErrosList = step.refId ? refList?.approvals : step.type && data ? data[step.type] : data.approvals
        const validationDocsList = step.refId ? refList?.documents : data.documents
        let errorsByStep: ValidationError[] = validationErrosList?.filter((error: ValidationError) => {
            if(error.isApprovalRequirementSatisfied) {
                return false
            }
            if (error.ref !== '') {
                return stepFields.includes(error.ref + '.' + error.slug)
            } else {
                return stepFields.includes(error.slug)
            }
        }) || []
        let docErrorsByStep: DocValidationError[] = validationDocsList?.filter((error: DocValidationError) => {
            if(error.approvals[0].isApprovalRequirementSatisfied) {
                return false
            } 
            if (error.approvals[0].ref !== '') {
                return stepFields.includes(error.approvals[0].ref + '.' + error.approvals[0].slug)
            } else {
                return stepFields.includes(error.approvals[0].slug)
            }
        }) || []
        payloadEl.push({
            step: step?.step,
            substep: step?.substep,
            link: step.link,
            name: step.name,
            validationErrors: errorsByStep,
            docErrors: docErrorsByStep,
            refId: step.refId,
        })
        
    })
    return payloadEl
}


type CaseReducers<State> = {
    kycApplicationApprovalsGetClear: CaseReducerType<State, object>
    kycApplicationApprovalsUpdateClear: CaseReducerType<State, object>
    kycApplicationFinalApproveClear: CaseReducerType<State, object>
    kycApplicationSendBackClear: CaseReducerType<State, object>
}

export const backKycApplicationSlice =  createSlice<State, CaseReducers<State>>({
    name: "backKycApplication",
    initialState,
    reducers: {
        kycApplicationApprovalsGetClear: (state, { payload }) => {
            state.kycApplicationApprovals = initialState.kycApplicationApprovals
            state.approvalErrors = initialState.approvalErrors
        },
        kycApplicationApprovalsUpdateClear: (state, { payload }) => {
            state.approvalsUpdated = initialState.approvalsUpdated
        },
        kycApplicationFinalApproveClear: (state, { payload }) => {
            state.finalApprove = initialState.finalApprove
        },
        kycApplicationSendBackClear: (state, { payload }) => {
            state.sentBack = initialState.sentBack
        },
    },
    extraReducers: (builder) => {
        builder.addCase(kycApplicationApprovalsGet.fulfilled, (state, action) => {
            state.kycApplicationApprovals = action.payload.data
            let steps = action.payload.steps
            state.approvalErrors = parseApprovalErrors(steps, action)
            state.multiApprove = false
        })
        builder.addCase(kycApplicationApprovalsUpdate.fulfilled, (state, action) => {
            state.approvalsUpdated = action.meta.arg.data
            //@ts-ignore
            state.multiApprove = action.meta.arg.params.multiApprove
        })
        builder.addCase(kycApplicationFinalApprove.fulfilled, (state, action) => {
            state.finalApprove = true
        })
        builder.addCase(kycApplicationSendBack.fulfilled, (state, action) => {
            state.sentBack = true
        })
        
    },
})

export const { kycApplicationApprovalsGetClear, kycApplicationApprovalsUpdateClear, kycApplicationFinalApproveClear, kycApplicationSendBackClear } = backKycApplicationSlice.actions
export default backKycApplicationSlice.reducer
