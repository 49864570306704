import { createSlice } from "@reduxjs/toolkit";
import { Components } from "../../../api/kyc/generated/client";
import { companyUpdate } from "../asyncThunks/companyUpdate";
import { ObjectKeyType } from "types";
import { CaseReducerType } from "../../store";

export type CompanyUpdatedResponse = Components.Schemas.CompanyResponse;

type State = {
  updated: CompanyUpdatedResponse;
};

const emptyProfile = {
    businessRelation: {
        isActive: false
    },
    companyId: '',
    name: '',
    legalForm: '',
    registrationNumber: '',
    placeOfIncorporation: '',
    phone: '',
    fax: '',
    createdAt: ''
}

const initialState: State = {
  updated: {
    companyId: '',
    profile: emptyProfile
  }
};

type CaseReducers<State> = {
  companyUpdateClear: CaseReducerType<State, object>;
};

export const companySlice = createSlice<State, CaseReducers<State>>({
  name: "company",
  initialState,
  reducers: {
    companyUpdateClear: (state, { payload }) => {
        state.updated = initialState.updated
    }
  },
  extraReducers: (builder) => {
    builder.addCase(companyUpdate.fulfilled, (state, action) => {
        state.updated = action.payload
    });
  },
});

export const { companyUpdateClear } = companySlice.actions;
export default companySlice.reducer;
