import { createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../../../api/kyc-backend/api";
import { AsyncThunkActionType } from "../../store";
import { AsyncThunkDataType } from "../../common";
import { CompanyResponse } from "../slices/archivedSlice";
import { Components, Paths } from "../../../api/kyc-backend/generated/client";

type RequestBody = Components.Schemas.ArchiveRequest;
type PathParameters = Paths.ArchiveCompanyToggle.PathParameters;

export const companyArchive: AsyncThunkActionType<
  CompanyResponse,
  AsyncThunkDataType<PathParameters, RequestBody, "data">
> = createAsyncThunk(
  "companyArchive",
  async ({ params, data, config }, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
        const response = await api.ArchiveCompanyToggle(params, data, config);
        return response.data as CompanyResponse;
    } catch (err: any) {
        return rejectWithValue(err.response.data);
    }
  }
);
