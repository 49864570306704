import { createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../../../api/kyc-backend/api";
import { AsyncThunkActionType } from "../../store";
import { AsyncThunkDataType } from "../../common";
import { CompanyUpdatedResponse } from "../slices/companySlice";
import { Components, Paths } from "../../../api/kyc-backend/generated/client";
import { store } from "../../store";

type UpdateCompanyRequest = Components.Schemas.UpdateCompany;
type PathParameters = Paths.UpdateCompanyProfile.PathParameters;

export const companyBackUpdate: AsyncThunkActionType<
  CompanyUpdatedResponse,
  AsyncThunkDataType<PathParameters, UpdateCompanyRequest, "data">
> = createAsyncThunk(
  "companyBackUpdate",
  async ({ params, data, config }, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      const response = await api.UpdateCompanyProfile(params, data, config);

      return response.data as CompanyUpdatedResponse;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);
