import { createAsyncThunk } from "@reduxjs/toolkit"
import { api2 } from "api/kyc/api"
import { AsyncThunkActionType } from "../../store"
import { AsyncThunkDataType } from "../../common"
import { Components, Paths } from "api/kyc/generated/client2"

type PathParameters = Paths.UploadDocument.PathParameters & Paths.UploadDocument.QueryParameters
type Data = Paths.UploadDocument.RequestBody;
type Response = 200


export const kycApplicationDocCreate: AsyncThunkActionType<
Response,
  AsyncThunkDataType<PathParameters, Data, "params">
> = createAsyncThunk("kycApplicationDocCreate", async ({ params, data, config }, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;
  try {
    let response = await api2.uploadDocument(params, data, config);
    return response.data;
  } catch (err: any) {
    return rejectWithValue(err.response.data);
  }
});