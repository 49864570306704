import { FC, useState } from 'react'
import { Row, Col, Divider, Select, Drawer, Space } from 'antd'
import { moment } from '../ui-elements/FormDate'
import Title from 'antd/es/typography/Title'
import { QuestionCircleOutlined } from '@ant-design/icons'
import styled from 'styled-components'
import { countries } from 'countries-list'
import FormInput from 'modules/kyc-front/ui-elements/FormInput'
import FormSelect from 'modules/kyc-front/ui-elements/FormSelect'
import FormDate from 'modules/kyc-front/ui-elements/FormDate'
import FormUpload from 'modules/kyc-front/ui-elements/FormUpload'
import { FormCheckboxRadio } from 'modules/kyc-front/ui-elements/FormCheckboxRadio'
import { KycAddressInfo, KycPassportInfo } from 'modules/kyc-front/KycDocsInfo'

const AlignedCol = styled(Col)`
    align-self: flex-end;
`

const TooltipHolder = styled.span`
    position: relative;
    bottom: 2px;
    font-size: 14px;
    margin-bottom: 2px;
    .anticon {
        margin-left: 5px;
    }
`

interface KProps {
    id?: string,
    person: any,
    type: 'ap' | 'ubo' | 'company' | 'individual',
    personType: 'ubos' | 'authorized-persons' | 'individual' | 'company',
    values: any,
    errors: any,
    showRoleType?: boolean,
    handleInputChange: any
    onSelectChange: any,
    onDateChange: any,
    disabled?: boolean,
    setFieldValue?: any,
    printRender?: boolean,
    docs?: any,
    newDoc?: any,
    docsGet?: any,
    docGetClear?: any,
    docsGetParams?: any,
    docCreate?: any,
    docClear?: any,
    docUrlGet?: any,
    docUrlClear?: any,
    docUrlResp?: any,
    personDocArchived? : any,
    docDelete?: any,
    docDeleteClear?: any
}

const KycProfileFormModule: FC<KProps> = ({id, person, type, personType, docs, newDoc, docsGet, docGetClear, docsGetParams, docUrlGet, docUrlClear, docUrlResp,  docCreate, docClear, docDelete, docDeleteClear, values, errors, showRoleType, handleInputChange, onSelectChange, onDateChange, disabled, personDocArchived, printRender}) => {
        const dateFormat = 'YYYY-MM-DD';
        const { Option } = Select;
        const [visible, setVisible] = useState(false);
        const [passportVisible, setPassportVisible] = useState(false);
        const showDrawer = () => {
            setVisible(true);
        };
        const onClose = () => {
            setVisible(false);
        };
        const showPassportDrawer = () => {
            setPassportVisible(true);
        };
        const onPassportClose = () => {
            setPassportVisible(false);
        };
        const switchChange = (checked: boolean) => {
          console.log(`switch to ${checked}`);
        }
        const personIsArchived = person.archivedAt ? true : false;
        return (
            <Space direction='vertical' size={'large'}>
                <Row gutter={30}>
                   <Col span={12}>
                       <FormInput label='Name *' name='personalDetails.firstName' error={errors.personalDetails?.firstName} onChange={handleInputChange} disabled={disabled} printRender={printRender} value={values.personalDetails.firstName} personId={id} type={personType} showApprove={!personIsArchived} />
                   </Col>
                   <Col span={12}>
                       <FormInput label='Last name *' name='personalDetails.lastName' error={errors.personalDetails?.lastName} onChange={handleInputChange} disabled={disabled} printRender={printRender} value={values.personalDetails.lastName} personId={id} type={personType} showApprove={!personIsArchived} />
                   </Col>
                </Row>
                {showRoleType &&
                    <Row gutter={30}>
                        <Col span={24}>
                            <FormSelect defaultValue={values.roleType} label='Role' id='roleType' error={errors.personalDetails?.roleType}  placeholder='Select role' onChange={(val) => onSelectChange(val, 'roleType')} disabled={disabled} personId={id} type={personType} schemaParent='extraFields' schemaName='roleType' hideApprove={personIsArchived} >    
                                <Option value='Director'>Director</Option>
                                <Option value='OtherIndividual'>Other</Option>
                            </FormSelect>
                        </Col>
                    </Row>
                }
                <Row gutter={30}>
                   <Col span={12}>
                       <FormDate label='Date of birth *' id='personalDetails.dateOfBirth' error={errors.personalDetails?.dateOfBirth} defaultPickerValue={moment('1980-01-10', dateFormat)} defaultValue={values.personalDetails.dateOfBirth && moment(values.personalDetails.dateOfBirth, dateFormat)} onChange={(val) => onDateChange(val, 'personalDetails.dateOfBirth')} disabled={disabled} personId={id} type={personType} hideApprove={personIsArchived} />
                   </Col>
                    <Col span={12}>
                        <FormInput label='Place of birth *' name='personalDetails.placeOfBirth' error={errors.personalDetails?.placeOfBirth} onChange={handleInputChange} disabled={disabled} printRender={printRender} value={values.personalDetails.placeOfBirth} personId={id} type={personType} showApprove={!personIsArchived} />
                    </Col>
                </Row>
                <FormInput label='Residential address *' name='personalDetails.residentialAddress.address' error={errors.personalDetails?.residentialAddress?.address} onChange={handleInputChange} disabled={disabled} printRender={printRender} value={values.personalDetails.residentialAddress.address} schemaParent='residentialAddress' schemaName='address' personId={id} type={personType} showApprove={!personIsArchived} />
                <Row gutter={30}>
                   <Col span={12}>
                       <FormInput label='Postcode *' name='personalDetails.residentialAddress.postcode' error={errors.personalDetails?.residentialAddress?.postcode} onChange={handleInputChange} disabled={disabled} printRender={printRender} value={values.personalDetails.residentialAddress.postcode} schemaParent='residentialAddress' schemaName='postcode' personId={id} type={personType} showApprove={!personIsArchived} />
                   </Col>
                   <Col span={12}>
                       <FormInput label='City *' name='personalDetails.residentialAddress.city' error={errors.personalDetails?.residentialAddress?.city} onChange={handleInputChange} disabled={disabled} printRender={printRender} value={values.personalDetails.residentialAddress.city} schemaParent='residentialAddress' schemaName='city' personId={id} type={personType} showApprove={!personIsArchived} />
                   </Col>
                </Row>
                <Row gutter={30}>
                    <Col span={12}>
                        <FormSelect defaultValue={values.personalDetails.residentialAddress.country} label='Residence country *' id='personalDetails.residentialAddress.country' error={errors.personalDetails?.residentialAddress?.country} placeholder='Select country' onChange={(val) => onSelectChange(val, 'personalDetails.residentialAddress.country')} disabled={disabled} schemaParent='residentialAddress' schemaName='country' personId={id} type={personType} hideApprove={personIsArchived} >
                            {Object.keys(countries).map((val, i) => {
                                const country = countries[val as keyof typeof countries]
                                return <Option key={'residence' + i} value={val}>{country.name}</Option>
                            })}
                        </FormSelect>
                    </Col>
                    <Col span={12}>
                        <FormSelect mode="multiple" defaultValue={(values.personalDetails.nationality && values.personalDetails.nationality  !== '') ? values.personalDetails.nationality.split(',') : values.personalDetails.nationality} label='Nationality/Nationalities *' id='personalDetails.nationality' error={errors.personalDetails?.nationality} placeholder='Select nationality' onChange={(val) => onSelectChange(val, 'personalDetails.nationality')} disabled={disabled} personId={id} type={personType} hideApprove={personIsArchived} >
                            {Object.keys(countries).map((val, i) => {
                                const country = countries[val as keyof typeof countries]
                                return <Option key={'nationality' + i} value={val}>{country.name}</Option>
                            })}
                        </FormSelect>
                    </Col>
                </Row>
                <FormInput label='Current occupation * (Please provide specific information)' name='personalDetails.occupation' error={errors.personalDetails?.occupation} onChange={handleInputChange} disabled={disabled} printRender={printRender} value={values.personalDetails.occupation} type={personType} personId={id} showApprove={!personIsArchived} />
                <Divider />
                <Title level={4}>Contact details</Title>
                <Row gutter={30}>
                   <Col span={12}>
                       <FormInput label='Email address *' name='personalDetails.email' error={errors.personalDetails?.email} onChange={handleInputChange} disabled={disabled} printRender={printRender} value={values.personalDetails.email} type={personType} personId={id} showApprove={!personIsArchived} />
                   </Col>
                   <Col span={12}>
                       <FormInput label='Mobile number (optional)' name='personalDetails.phone' error={errors.personalDetails?.phone} onChange={handleInputChange} disabled={disabled} printRender={printRender} value={values.personalDetails.phone} type={personType} personId={id} showApprove={!personIsArchived} />
                   </Col>
                </Row>
                <Divider />
                <div>
                    <Title style={(type === 'ap' || type === 'ubo') && printRender ? {marginBottom: '-10px', marginTop: '-2px'} : {}} level={4}>Passport / ID Card<TooltipHolder className='hide-print'><QuestionCircleOutlined onClick={showPassportDrawer} /></TooltipHolder></Title>
                    <span className={type === 'ap' || type === 'ubo' ? 'hide-print' : ''}>(European national identity card only)</span>
                </div>
                <Drawer rootClassName='hide-print' title="Passport / ID Card" placement="right" onClose={onPassportClose} open={passportVisible}>
                    <KycPassportInfo />
                </Drawer>
                <FormCheckboxRadio id='personalDetails.identityDocument.kind' error={errors.personalDetails?.identityDocument?.kind} type='radio' onChange={handleInputChange}
                    options={[{
                            label: 'Passport',
                            value: 'PassportKind'
                        },
                        {
                            label: 'ID Card',
                            value: 'NationalIDCardKind'
                        }
                    ]
                } disabled={disabled} showApprove={false} schemaParent='identityDocument' schemaName='kind' approveType={personType} personId={id}/>
                <Row gutter={30}>
                   <Col span={12}>
                       <FormInput label='Document Number *' name={`personalDetails.identityDocument.number`} error={errors.personalDetails?.identityDocument?.number} onChange={handleInputChange} disabled={disabled} printRender={printRender} value={values.personalDetails.identityDocument.number} schemaParent='identityDocument' schemaName='number' personId={id} type={personType} showApprove={!personIsArchived} />
                   </Col>
                   <Col span={12}>
                       <FormInput label='Place of issue *' name={`personalDetails.identityDocument.placeOfIssue`} error={errors.personalDetails?.identityDocument?.placeOfIssue} onChange={handleInputChange} disabled={disabled} printRender={printRender} value={values.personalDetails.identityDocument.placeOfIssue} schemaParent='identityDocument' schemaName='placeOfIssue' personId={id} type={personType} showApprove={!personIsArchived} />
                   </Col>
                </Row>
                <Row gutter={30} id='documentDates'>
                    <Col span={12}>
                       <FormDate label='Date of issue *' id={`personalDetails.identityDocument.dateOfIssue`} error={errors.personalDetails?.identityDocument?.dateOfIssue} defaultValue={values.personalDetails.identityDocument.dateOfIssue && moment(values.personalDetails.identityDocument.dateOfIssue, dateFormat)}  onChange={(val) => onDateChange(val, `personalDetails.identityDocument.dateOfIssue`)} disabled={disabled} schemaParent='identityDocument' schemaName='dateOfIssue' personId={id} type={personType} hideApprove={personIsArchived} />
                    </Col>
                    <Col span={12}>
                       <FormDate label='Date of expiry *' id={`personalDetails.identityDocument.dateOfExpiry`} error={errors.personalDetails?.identityDocument?.dateOfExpiry} defaultValue={values.personalDetails.identityDocument.dateOfExpiry && moment(values.personalDetails.identityDocument.dateOfExpiry, dateFormat)} onChange={(val) => onDateChange(val, `personalDetails.identityDocument.dateOfExpiry`)} disabled={disabled} schemaParent='identityDocument' schemaName='dateOfExpiry' personId={id} type={personType} hideApprove={personIsArchived} />
                    </Col>
                </Row>
                <Row gutter={30} id='nationalityDocument'>
                    <Col span={24}>
                        <FormSelect defaultValue={values.personalDetails.identityDocument?.nationality} label='Nationality on document *' id={`personalDetails.identityDocument.nationality`} error={errors.personalDetails?.identityDocument ? errors.personalDetails?.identityDocument?.nationality : undefined}  placeholder='Nationality on document' onChange={(val) => onSelectChange(val, `personalDetails.identityDocument.nationality`)} disabled={disabled} schemaParent='identityDocument' schemaName='nationality' personId={id} type={personType} hideApprove={personIsArchived} >
                            {values.personalDetails.nationality !== '' && 
                                <>
                                    {values.personalDetails?.nationality.split(',').map((val: string, i: number) => {
                                        //@ts-ignore
                                        const country = countries[val]
                                        return <Option key={`identityDocument.nationality` + i} value={val}>{country.name}</Option>
                                    })}
                                </>
                            }
                        </FormSelect>
                    </Col>
                </Row>
                {values.personalDetails.identityDocument.kind === 'NationalIDCardKind' ? (
                    <Row gutter={30}>
                        <AlignedCol span={12}>
                            <FormUpload /*maxCount={1}*/ name='First page of the ID card' disabled={disabled}  uploadAdmin={true} /*docs={docs}*/ docType='IDCardFront' isErrorMessage={errors.personalDetails?.IDCardFront} personId={id} personType={personType} /*newDoc={newDoc} docCreate={docCreate} docClear={docClear} docsGet={docsGet} docGetClear={docGetClear} docsGetParams={docsGetParams} docUrlGet={docUrlGet} docUrlClear={docUrlClear} docUrlResp={docUrlResp} docDelete={docDelete} docArchived={personDocArchived} docDeleteClear={docDeleteClear}*/ type={type} hideApprove={personIsArchived}/>
                        </AlignedCol>
                        <AlignedCol span={12}>
                            <FormUpload /*maxCount={1}*/ name='Second page of the ID card' disabled={disabled} uploadAdmin={true} /*docs={docs}*/ docType='IDCardBack' isErrorMessage={errors.personalDetails?.IDCardBack} personId={id} personType={personType} /*newDoc={newDoc} docCreate={docCreate} docClear={docClear} docsGet={docsGet} docGetClear={docGetClear} docsGetParams={docsGetParams} docUrlGet={docUrlGet} docUrlClear={docUrlClear} docUrlResp={docUrlResp} docDelete={docDelete} docArchived={personDocArchived} docDeleteClear={docDeleteClear}*/ type={type} hideApprove={personIsArchived} />
                        </AlignedCol>
                    </Row>
                ) : (
                    <Row gutter={30}>
                        <Col span={24}>
                            <FormUpload name='Passport' disabled={disabled} onSwitchChange={switchChange} uploadAdmin={true} /*docs={docs}*/ docType='PassportFront' isErrorMessage={errors.personalDetails?.PassportFront} personId={id} personType={personType} /*newDoc={newDoc} docCreate={docCreate} docClear={docClear} docsGet={docsGet} docGetClear={docGetClear} docsGetParams={docsGetParams} docUrlGet={docUrlGet} docUrlClear={docUrlClear} docUrlResp={docUrlResp} docDelete={docDelete} docArchived={personDocArchived} docDeleteClear={docDeleteClear}*/ type={type} hideApprove={personIsArchived} />
                        </Col>
                    </Row>
                )}
                <div className='hide-print' style={{'marginBottom': '-10px'}}>
                    <Title level={4}>Proof of address<TooltipHolder className='hide-print'><QuestionCircleOutlined onClick={showDrawer} /></TooltipHolder></Title>
                    <span>(recent and not older than 12 months)</span>
                </div>
                <Drawer rootClassName='hide-print' title="Proof of address" placement="right" onClose={onClose} open={visible}>
                    <KycAddressInfo />
                </Drawer>
                <FormUpload name='Proof of address' disabled={disabled} /*docs={docs}*/ docType='ProofOfAddress' uploadAdmin={true} isErrorMessage={errors.personalDetails?.ProofOfAddress} personId={id} personType={personType} /*newDoc={newDoc} docCreate={docCreate} docClear={docClear} docsGet={docsGet} docGetClear={docGetClear} docsGetParams={docsGetParams} docUrlGet={docUrlGet} docUrlClear={docUrlClear} docUrlResp={docUrlResp} docArchived={personDocArchived} docDelete={docDelete} docDeleteClear={docDeleteClear}*/ type={type} hideApprove={personIsArchived} />
                <div className='hide-print' style={{'marginBottom': '-10px'}}>
                    <Title level={4}>Other documents</Title>
                    <span>(additional documents specifically requested by Bitclear)</span>
                </div>
                <FormUpload name='Other documents' disabled={disabled} /*docs={docs}*/  docType='OtherDocumentKind' uploadAdmin={true} isErrorMessage={errors.personalDetails?.OtherDocumentKind} personId={id} personType={personType} /*newDoc={newDoc} docCreate={docCreate} docClear={docClear} docsGet={docsGet} docGetClear={docGetClear} docsGetParams={docsGetParams} docUrlGet={docUrlGet} docUrlClear={docUrlClear} docUrlResp={docUrlResp} docArchived={personDocArchived} docDelete={docDelete} docDeleteClear={docDeleteClear}*/ type={type} hideApprove={personIsArchived} />
                {!printRender && <div style={{'marginBottom': '15px'}}></div>}
            </Space>
        )

}

export default KycProfileFormModule