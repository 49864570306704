import { FC, ReactNode } from 'react'
import { Row, Col, Space } from 'antd'
import Text from 'antd/es/typography/Text'
import styled from 'styled-components'
import { useSelector } from 'hooks/useSelector'
import FormInput from 'modules/kyc-front/ui-elements/FormInput'
import { FormCheckboxRadio, FormCheckboxRadioStandalone } from 'modules/kyc-front/ui-elements/FormCheckboxRadio'
import ApproveInput from "modules/kyc-backend/ui-elements/Approve"
import { themeColor } from 'styles/themeStyles'

const CheckboxWrapperStyles = styled.div`
    label {
        margin-bottom: 15px;
        line-height: 20px;
        gap: 25px;
    }
    input {
        position: relative;
        top: 3px;
    }
`
const ErrorContainer = styled.div`
    color: ${themeColor.red};
`

interface CProps {
    error?: string,
    children: ReactNode
}

const CheckboxWrapper: FC<CProps> = ({error, children}) => {
    return (
        <CheckboxWrapperStyles className='checkbox-wrapper'>
            {children}
            {error && error !== '' &&
                <ErrorContainer>{error}</ErrorContainer>
            }
        </CheckboxWrapperStyles>
    )
}

const DeclarationWrapper = styled.div`
    margin-top: 20px;
`


interface KProps {
    values: any,
    errors: any,
    handleInputChange: any,
    disabled?: boolean,
    printRender?: boolean,
    person?: any,
    type: 'individual' | 'authorized-persons' | 'ubos',
    uboType?: boolean
}

const KycSourceOfWealthForm: FC<KProps> = ({values, errors, handleInputChange, disabled, printRender, person, uboType, type}) => {
    const envs = useSelector((state) => state.general.envs);   
    const personIsArchived = person?.archivedAt ? true : false;
    return (
        <Space direction='vertical' size={'large'}>
            {uboType ? (
                <DeclarationWrapper className='print10'>
                <Row justify="space-between">
                    <Col>
                        <Text><b>1. Beneficial Owners</b>*</Text>
                    </Col>
                    <Col>
                        {envs.admin && !personIsArchived &&
                            <ApproveInput name='roleType' type='ubos' personId={person.beneficialOwnerId} />
                        }
                    </Col>
                </Row>
                <Text>The above person has been identified being the beneficial owner as referred to in article 3, paragraph 1 (a) DDO. <b>Please only tick ONE of below options, starting with the first. If the first does not apply, go to the second.  If the second does not apply go to the third. If the third does not apply, another person has to be declared Beneficial Owner.</b></Text>
                <CheckboxWrapper error={errors?.roleType}>
                            <FormCheckboxRadio id='roleType' type='radio' onChange={handleInputChange}
                                options={[{
                                        label: 'A natural person who ultimately directly or indirectly holds or controls a share or voting rights amounting to 25% or more of that legal entity or receives 25% or more of the profits of that legal entity.',
                                        value: 'HoldsMoreThan25PercentageOfShares'
                                    },
                                    {
                                        label: 'A natural person who ultimately in another way exercises control over the business management of that legal entity.',
                                        value: 'UltimatelyControlOverBusiness'
                                    },
                                    {
                                        label: 'A natural person who is a member of the governing body if - after exhausting all possibilities and provided there is no suspicion - no person mentioned above has been determined.',
                                        value: 'MemberOfGoverningBody'
                                    }
                                ]
                            } disabled={disabled} showApprove={false} error={errors?.roleType} approveType={type} />
                        </CheckboxWrapper>
            </DeclarationWrapper>
            ) : (
                <div>
                    <Text><b>1. Beneficial Owners</b></Text><br/>
                    <Text>I hereby confirm, that I am the sole beneficial owner of all assets brought in to this business relationship. </Text>
                    <Text>I am the contracting party, I am not acting on behalf of a third party and I will not share my access to Bitclear AG’s platform with anyone else.</Text>
                </div>
            )}
            <div>
                <Row justify="space-between">
                    <Col>
                        <Text><b>2. Source of Wealth</b></Text>
                    </Col>
                    <Col>
                        {envs.admin &&
                            <ApproveInput name='sourceOfWealthBusinessActivities' type={type} />
                        }
                    </Col>
                </Row>
                <Text>{uboType ? 'The wealth of above Beneficial Owner is not derived from any illegal activities but is derived from:' : 'My wealth is not derived from any illegal activities but is derived from:'}</Text>
            </div>
            <CheckboxWrapper error={errors?.sourceOfWealth}>
                <FormCheckboxRadioStandalone id='sourceOfWealthBusinessActivities' type='checkbox' onChange={handleInputChange} label='Business activities (please name company name and business sector below)' disabled={disabled} error={errors?.sourceOfWealthBusinessActivities}  />
                <FormCheckboxRadioStandalone id='sourceOfWealthInheritance' type='checkbox' onChange={handleInputChange} label='Inheritance (please name testator and relationship below)' disabled={disabled} error={errors?.sourceOfWealthInheritance} />
                <FormCheckboxRadioStandalone id='sourceOfWealthProfessionalOccupation' type='checkbox' onChange={handleInputChange} label='Professional occupation (please name employer and occupation below)' disabled={disabled} error={errors?.sourceOfWealthProfessionalOccupation} />
                <FormCheckboxRadioStandalone id='sourceOfWealthOther' type='checkbox' onChange={handleInputChange} label='Other (please provide details below)' disabled={disabled} error={errors?.sourceOfWealthOther} />
            </CheckboxWrapper>
            <FormInput label='Please describe the source of wealth: *' subLabel='(in 2-3 sentences)' className='sowIndividualPrint' subLabelClass='sowDescriptionIndividualPrint' name='sourceOfWealthDescription' component='textarea' onChange={handleInputChange} value={values.sourceOfWealthDescription} disabled={disabled} printRender={printRender} error={errors?.sourceOfWealthDescription} type={type} />
            <FormCheckboxRadio label='Total estimated net worth ( EURO )' subLabel='(properties, cash, equity, etc.)' id='estimatedNetWorth' type='radio' onChange={handleInputChange}
            options={[{
                    label: '< 100,000',
                    value: '0'
                },
                {
                    label: '100,000 - 1 milion',
                    value: '100000'
                },
                {
                    label: '1 - 5 milion',
                    value: '1000001'
                },
                {
                    label: '> 5 milion',
                    value: '5000001'
                }
            ]
            } disabled={disabled} className="netWorthPrintIndividual" error={errors?.estimatedNetWorth} approveType={type} />
            {uboType ? (
                <></>
            ) : (
                <>
                    <label><b>3. Estimated amount of future exchange transactions settled through Bitclear AG per year:</b></label>
                    <Row gutter={30}>
                        <Col md={12}>
                            <FormInput label='Amount (EUR)' name='estimatedAmountOfEUR' error={errors?.estimatedAmountOfEUR} onChange={handleInputChange} value={values.estimatedAmountOfEUR} disabled={disabled} printRender={printRender} type='individual' formatting='numbers' />
                        </Col>
                    </Row>
                </>
            )}
            <div>
                <Text><b>{uboType ? '3.' : '4.'} Tax Compliance</b></Text><br/>
                <Text>I also recognize that the Beneficial Owner may be subject to tax where tax resident and hereby undertakes to obtain advice as necessary to ensure that the due taxes are paid and statutory compliance is honored. To this effect, Bitclear AG accepts no liability for any tax consequences.</Text>
            </div>
        </Space>
        )

}

export default KycSourceOfWealthForm