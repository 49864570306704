import { FC, useEffect } from 'react'
import _ from 'lodash';
import { Space } from 'antd'
import { useSelector } from 'hooks/useSelector'
import { dispatch } from 'store/store'
import FormUpload from 'modules/kyc-front/ui-elements/FormUpload'
import { companyApprovedFieldsGet } from 'store/kyc-backend/asyncThunks/companyApprovedFieldsGet'

interface KProps {
    id?: string,
    errors: any,
    disabled?: boolean,
    type: 'ap' | 'ubo' | 'company' | 'individual',
    personType?: 'ubos' | 'authorized-persons' | 'individual' | 'company'
}


const KycCompanyDocumentsForm: FC<KProps> = ({errors, disabled, type, personType, id}) => {
    const envs = useSelector((state) => state.general.envs);
    useEffect(() => {
        if(envs.admin) {
            dispatch(companyApprovedFieldsGet({params: {companyId: envs.profileId}}))
        }
    }, []);
    return (
        <Space direction='vertical' size={'large'}>
            <div className='hide-print' style={{'marginBottom': '-10px'}}>
                <span>Additional company documents specifically requested by Bitclear</span>
            </div>
            <FormUpload key={id} name='Other documents' disabled={disabled} docType='OtherDocumentKind' uploadAdmin={true} isErrorMessage={errors?.OtherDocumentKind} personId={id && id !== '' ? id : envs.profileId} personType={personType} type={type} maxSize={envs.admin ? 15 : 8} />
        </Space>
    )
	
}

export default KycCompanyDocumentsForm
