import { createSlice } from "@reduxjs/toolkit";
import { Components, Paths } from "../../../api/kyc/generated/client";
import { individualGet } from "../asyncThunks/individualGet";
import { individualUpdate } from "../asyncThunks/individualUpdate";
import { ObjectKeyType } from "types";
import { CaseReducerType } from "../../store";

export type IndividualBasicResponse = Components.Schemas.IndividualProfile;

type State = {
  updated: IndividualBasicResponse;
  individual: IndividualBasicResponse
};

const emptyProfile = {
    beneficialOwnerId: '',
    sourceOfWealthBusinessActivities: false,
    sourceOfWealthProfessionalOccupation: false,
    sourceOfWealthInheritance: false,
    sourceOfWealthOther: false,
    isPoliticallyExposedPerson: false,
    isUSNationality: false,
    createdAt: ''
}

const emptyIndividual = {
    individualId: '',
    businessRelation: {
        isActive: false
    },
    profile: emptyProfile,
    createdAt: '',
    reviewStatus: ''
}


const initialState: State = {
  updated: emptyIndividual,
  individual: emptyIndividual
};

type CaseReducers<State> = {
  individualUpdateClear: CaseReducerType<State, object>;
  individualGetClear: CaseReducerType<State, object>;
};

export const individualSlice = createSlice<State, CaseReducers<State>>({
  name: "individual",
  initialState,
  reducers: {
    individualUpdateClear: (state, { payload }) => {
      state.updated = initialState.updated
    },
    individualGetClear: (state, { payload }) => {
      state.individual = initialState.updated
    }
  },
  extraReducers: (builder) => {
    builder.addCase(individualGet.fulfilled, (state, action) => {
      state.individual = action.payload
    });
    builder.addCase(individualUpdate.fulfilled, (state, action) => {
      state.updated = action.payload
    });
  },
});

export const { individualUpdateClear, individualGetClear } = individualSlice.actions;
export default individualSlice.reducer;
