import { SessionResponse } from 'modules/keycloak/api/types'
import { jwtDecode } from 'jwt-decode';
import { noop } from 'lodash';
import { useCallback, useEffect } from 'react';
import { dispatch, store } from 'store/store';
import { logout } from '../store/authSlice';
import { refreshToken as keycloakRefreshToken } from '../store/refreshToken';

const MIN_1 = 60 * 1000;

let refreshTimeout: NodeJS.Timeout;

interface IProps {
  keycloakDomain: string;
  client_id: string;
  is_backend: boolean;
  onRefreshSuccess?: (res: SessionResponse) => void;
  onRefreshError?: () => void;
}

export const useKeycloakRefreshToken = ({
  keycloakDomain,
  client_id,
  is_backend,
  onRefreshSuccess = noop,
  onRefreshError = () => dispatch(logout()),
}: IProps): void => {
  const refreshToken = useCallback((skipRefreshing = false) => {
    const getIntervalMillis = (accessToken: string | undefined) => {
      if (accessToken) {
        const { exp: expires }: { exp: number } = jwtDecode(accessToken);
        const now = Math.floor(new Date().getTime() / 1000);
        const diffInMillis = Math.floor(expires - now) * 1000;
        const minuteBeforeExpire = diffInMillis - MIN_1;
        const oneSecond = 1000;
        return diffInMillis > MIN_1 ? minuteBeforeExpire : oneSecond;
      }
      return MIN_1;
    };

    const accessToken = store.getState().general.auth.session?.access_token;

    clearTimeout(refreshTimeout);
    refreshTimeout = setTimeout(() => refreshToken(), getIntervalMillis(accessToken));

    if (!skipRefreshing && accessToken) {
      dispatch(keycloakRefreshToken({ params: { keycloakDomain, client_id, is_backend } }))
        .unwrap()
        .then(onRefreshSuccess)
        .catch(() => {
          onRefreshError();
        });
    }
  }, [keycloakDomain, client_id, is_backend, onRefreshSuccess, onRefreshError]);

  useEffect(() => {
    refreshToken(true);
    return () => clearTimeout(refreshTimeout); 
  }, [refreshToken]);
};
