import { createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../../../api/kyc/api";
import { AsyncThunkActionType } from "../../store";
import { AsyncThunkDataType } from "../../common";
import { Components, Paths } from "../../../api/kyc/generated/client";

type CreateIndividualDocsRequest = Components.Schemas.IndividualDocumentForm;
type resp = Components.Schemas.IndividualDocumentEntry;

export const individualDocCreate: AsyncThunkActionType<
    resp,
    AsyncThunkDataType<null, CreateIndividualDocsRequest, "data">
    > = createAsyncThunk(
    "individualDocCreate",
    async ({ params, data, config }, thunkAPI) => {
        const { rejectWithValue } = thunkAPI;
        try {
            const response = await api.UploadIndividualDocument(params, data, config);
            return response.data as resp;
        } catch (err: any) {
            return rejectWithValue({
                error: true,
                status: err?.response.status, 
                data: err?.response.data,
                ...config
            });
        }
    }
);
