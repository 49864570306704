import { createAsyncThunk } from "@reduxjs/toolkit"
import { api2 } from "api/kyc/api"
import { AsyncThunkActionType } from "../../store"
import { AsyncThunkDataType } from "../../common"
import { Components, Paths } from "api/kyc/generated/client2"

type PathParameters = Paths.GetApplication.PathParameters

type KycApplication = Components.Schemas.KycApplication

interface Address {
    address: string;
    postcode: string;
    city: string;
    country: string;
}
  
interface IdentityDocument {
    number: string;
    placeOfIssue: string;
    nationality: string;
    kind: string;
}
  
interface Document {
    id: string;
    fileName: string;
    mimeType: string;
}

interface AuthorizedPerson {
    id: string;
    firstName: string;
    lastName: string;
    placeOfBirth: string;
    dateOfBirth: string;
    phone: string;
    email: string;
    occupation: string;
    roleType: string;
    nationality: string;
    identityDocument: IdentityDocument;
}

interface BeneficialOwner {
    id: string;
    firstName: string;
    lastName: string;
    nationality: string;
    placeOfBirth: string;
    phone: string;
    email: string;
    occupation: string;
    estimatedNetWorth: string;
    estimatedAmountOfEUR: string;
    sourceOfWealthBusinessActivities: string;
    sourceOfWealthProfessionalOccupation: string;
    sourceOfWealthInheritance: string;
    sourceOfWealthOther: string;
    sourceOfWealthDescription: string;
    isPoliticallyExposedPerson: string;
    isUSNationality: string;
    identityDocument: IdentityDocument;
    residentialAddress: Address;
}

type IndividualBody = {
    firstName?: string;
    lastName?: string;
    residentialAddress?: Address;
    nationality?: string;
    identityDocument?: IdentityDocument;
    placeOfBirth?: string;
    phone?: string;
    email?: string;
    occupation?: string;
    estimatedNetWorth?: string; 
    sourceOfWealthBusinessActivities?: boolean;
    sourceOfWealthProfessionalOccupation?: boolean;
    sourceOfWealthInheritance?: boolean;
    sourceOfWealthOther?: boolean;
    sourceOfWealthDescription?: string;
    isPoliticallyExposedPerson?: boolean;
    isUSNationality?: boolean;
    documents?: Document[];
}

type CompanyBody = {
    id: string;
    activitiesAndBusinessModel: string;
    geographicalArea: string;
    annualTurnoverEUR: string;
    estimatedAmountOfEUR: string;
    fundingOfBusinessDescription: string;
    fundingOfBusinessType: string;
    businessLicence: string;
    numberOfEmployees: string;
    name: string;
    legalForm: string;
    dateOfIncorporation: string;
    registrationNumber: string;
    placeOfIncorporation: string;
    website: string;
    headOfficeAddress: Address;
    registeredAddress: Address;
    beneficialOwners: BeneficialOwner[];
    authorizedPersons: AuthorizedPerson[];
}

interface IndividualKycApplicationSection extends Components.Schemas.KycApplicationSection {
  values: (Components.Schemas.KycApplicationFieldValueObject | { key: keyof IndividualBody, value: any })[];
}

interface CompanyKycApplicationSection extends Components.Schemas.KycApplicationSection {
    values: (Components.Schemas.KycApplicationFieldValueObject | { key: keyof CompanyBody, value: any })[];
  }
  

// Interface that represents the response including IndividualBody fields within values
export interface ProfileResponse extends Omit<KycApplication, 'values'> {
  //values: IndividualKycApplicationSection | CompanyKycApplicationSection;
  values: any
}


export const kycApplicationGet: AsyncThunkActionType<
  ProfileResponse,
  AsyncThunkDataType<PathParameters, null, "params">
> = createAsyncThunk("kycApplicationGet", async (data, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;
  try {
    let response = await api2.getApplication(data.params, null, data?.config);
    return response.data as ProfileResponse;
  } catch (err: any) {
    return rejectWithValue(err.response.data);
  }
});