import { createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../../../api/kyc-backend/api";
import { FieldsResponse } from '../slices/approvedSlice'
import { AsyncThunkActionType } from "../../store";
import { AsyncThunkDataType } from "../../common";
import { Components, Paths } from "../../../api/kyc-backend/generated/client";

type PathParameters = Paths.CompanyApprovedFields.PathParameters;

export const companyApprovedFieldsGet: AsyncThunkActionType<
  FieldsResponse,
  AsyncThunkDataType<PathParameters, null, "params">
> = createAsyncThunk("companyApprovedFieldsGet", async (data, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;
  try {
    const response = await api.CompanyApprovedFields(data.params, null, data?.config);
    return response.data as FieldsResponse;
  } catch (err: any) {
    return rejectWithValue(err.response.data);
  }
});
