import { createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../../../api/kyc/api";
import { ReadyForReviewResponse } from '../slices/statusSlice'
import { AsyncThunkActionType } from "../../store";
import { AsyncThunkDataType } from "../../common";
import { Components, Paths } from "../../../api/kyc/generated/client";

type PathParameters = Paths.CompanyValidateProfileReadyForReview.PathParameters;

export const readyForReviewValidate: AsyncThunkActionType<
  ReadyForReviewResponse,
  AsyncThunkDataType<PathParameters, null, "params">
> = createAsyncThunk(
  "readyForReviewValidate",
  async ({ params, data, config }, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      const response = await api.CompanyValidateProfileReadyForReview(params, null, config);
      return response.data as ReadyForReviewResponse;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);
