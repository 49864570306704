import { createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../../../api/kyc-backend/api";
import { AsyncThunkActionType } from "../../store";
import { AsyncThunkDataType } from "../../common";
import { AccountResponse } from "../slices/fxAccountSlice";
import { Components, Paths } from "../../../api/kyc-backend/generated/client";

type PathParameters = Paths.CreateCompanyFxAccount.PathParameters;

export const companyFxAccountCreate: AsyncThunkActionType<
  AccountResponse,
  AsyncThunkDataType<PathParameters, null, "data">
> = createAsyncThunk(
  "companyFxAccountCreate",
  async ({ params, data, config }, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      const response = await api.CreateCompanyFxAccount(params, null, config);
      return response.data as AccountResponse;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);
