import { createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../../../api/kyc/api";
import { AsyncThunkActionType } from "../../store";
import { AsyncThunkDataType } from "../../common";
import { Components, Paths } from "../../../api/kyc/generated/client";

type PersonsResponse = Components.Schemas.AuthorizedPersonProfile;
type PathParameters = Paths.AuthorizedPersons.PathParameters;

export const authorizedPersonsGet: AsyncThunkActionType<
  PersonsResponse[],
  AsyncThunkDataType<PathParameters, null, "params">
> = createAsyncThunk("authorizedPersonsGet", async (data, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;
  try {
    const response = await api.AuthorizedPersons(data.params, null, data?.config);
    return response.data as PersonsResponse[];
    //return response
  } catch (err: any) {
    return rejectWithValue(err.response.data);
  }
});
