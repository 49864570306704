import { createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../../../api/kyc/api";
import { AsyncThunkActionType } from "../../store";
import { AsyncThunkDataType } from "../../common";
import { Components, Paths } from "../../../api/kyc/generated/client";

type UbosResponse = Components.Schemas.BeneficialOwnerProfile;
type PathParameters = Paths.BeneficialOwners.PathParameters;

export const ubosGet: AsyncThunkActionType<
  UbosResponse[],
  AsyncThunkDataType<PathParameters, null, "params">
> = createAsyncThunk("ubosGet", async (data, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;
  try {
    const response = await api.BeneficialOwners(data.params, null, data?.config);
    return response.data as UbosResponse[];
    //return response
  } catch (err: any) {
    return rejectWithValue(err.response.data);
  }
});
