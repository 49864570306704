import { createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../../../api/kyc/api";
import { IndividualReadyForReviewResponse } from '../slices/statusSlice'
import { AsyncThunkActionType } from "../../store";
import { AsyncThunkDataType } from "../../common";
import { Components, Paths } from "../../../api/kyc/generated/client";

type RequestNavType = {
    individualId: string;
    step?: number;
}


export const individualReadyForReviewValidate: AsyncThunkActionType<
  IndividualReadyForReviewResponse,
  AsyncThunkDataType<RequestNavType, null, "params">
> = createAsyncThunk(
  "individualReadyForReviewValidate",
  async ({ params, data, config }, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await api.IndividualValidateProfileReadyForReview(null, null, config);
      return {...response.data, params} as IndividualReadyForReviewResponse;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);
