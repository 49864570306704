import { FC, ReactNode, useEffect } from 'react'
import styled from 'styled-components'
import { CloseCircleOutlined } from '@ant-design/icons'
import { Link, useNavigate } from 'react-router-dom'
import { Typography } from 'antd'
import { useSelector } from 'hooks/useSelector'
import { redirectToError } from 'store/kyc-front/slices/errorsSlice'
import { dispatch } from 'store/store'
import { themeColor } from 'styles/themeStyles'
import { camelCaseToName, nameParse, extractName } from 'utilities/nameParsers'
const { Paragraph } = Typography

export const ErrorParagraph = styled(Paragraph)`
    .anticon {
        color: ${themeColor.red};
        margin-right: 8px;
    }
    a {
        margin-left: 5px;
        color: ${themeColor.gray}
    }
    .details {
        transform: rotate(90deg);
        display: inline-block;
    }
`

const StyledList = styled.div`
    ul {
        margin-top: 10px;
        li {
            margin-left: 35px;
            margin-bottom: 5px;
        }
    }    
`

const LinkEl = styled.a`
    border: 1px solid ${themeColor.grayBasic};
    padding: 2px 7px;
`

interface EProps {
    visibleErrors: string[],
    elem: string | number,
    header: ReactNode,
    route: string,
    additionalAction?: any,
    business?: boolean,
    errorsListing: any,
    showErrors: (value: any) => void,
    formName: string,
    id?: string,
    isApproval?: boolean,
    listingType?: 'missingField' | 'missingApprove' | 'missingDocument'
}

export const ErrorElement: FC<EProps> = ({visibleErrors, elem, showErrors, errorsListing, header, route, business, formName, listingType, additionalAction, id, isApproval}) => {
    let err = id ? visibleErrors.find(error => error === id) : visibleErrors.find(error => error === elem);
    let navigate = useNavigate();
    const envs = useSelector((state) => state.general.envs)
    const nav = useSelector((state) => state.general.nav)
    const adminProfile = useSelector((state) => state.admin.admin)
    let admin = adminProfile?.profile?.adminId
    let { formUpdated } = nav
    let isStandaloneAdmin = envs.admin && envs.type === 'standalone'
    const moveToError = (route: string, error: any, formName: string, errorType: 'error' | 'approve') => {
        if(additionalAction && formUpdated.updated) {
            additionalAction();
            if(listingType !== 'missingApprove') dispatch(redirectToError({route, error, formName, step: elem}))
        } else {
            if(listingType !== 'missingApprove') dispatch(redirectToError({route, error, formName, step: elem}))
            navigate(isStandaloneAdmin ? envs.routeSuffix + '/' + envs.profileId + route : envs.routeSuffix + route);
        }
        
    }
    
    return (
        <ErrorParagraph>
            <CloseCircleOutlined />{header}<LinkEl onClick={() => showErrors( id ? id : elem)}>Details <span className='details'>&gt;</span></LinkEl><LinkEl style={{'cursor': 'pointer', 'marginLeft': '3px'}} onClick={() => moveToError(route, 'error', formName, errorsListing.validationErrors[0] && errorsListing.validationErrors[0].approve ? 'approve' : 'error' )}> Fix issues &gt;</LinkEl>
                {err &&
                    <StyledList>
                            {Array.isArray(errorsListing.validationErrors) ? (
                                <ul style={{marginBottom: '-5px'}}>
                                    {errorsListing.validationErrors.map((errorMsg: any) => {
                                        if(isApproval) {
                                            return (<li><b>{camelCaseToName(errorMsg.slug)}</b> is not approved by <b>{errorMsg?.approvedBy ? <span>{errorMsg.approvedBy.find((el: string) => el === admin) ? 'Other admin' : 'You'}</span> : <span>You</span>}</b></li>)
                                        } else {
                                            return ( <li><b>{camelCaseToName(errorMsg.slug)}</b> is missing from <b>{errorsListing.name}</b> step</li>)
                                        }
                                    })}
                                </ul>
                            ) : (<></>)}
                            {Array.isArray(errorsListing.docErrors) ? (
                                <ul style={{marginTop: '10px'}}>
                                    {errorsListing.docErrors.map((errorMsg: any) => {
                                        return (<li><b>{camelCaseToName(errorMsg.approvals[0].slug)}</b> is not approved by <b>{errorMsg?.approvals[0]?.approvedBy ? <span>{errorMsg.approvals[0].approvedBy.find((el: string) => el === admin) ? 'Other admin' : 'You'}</span> : <span>You</span>}</b></li>)
                                    })}
                                </ul>
                            ) : (<></>)}
                    </StyledList>
                }
        </ErrorParagraph>
    )
}