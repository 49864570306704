import { createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../../../api/kyc-backend/api";
import { AsyncThunkActionType } from "../../store";
import { AsyncThunkDataType } from "../../common";
import { ProfileResponse } from "../slices/statusSlice";
import { Components, Paths } from "../../../api/kyc-backend/generated/client";

type PathParameters = Paths.ValidateProfile.PathParameters;

export const companyBackValidate: AsyncThunkActionType<
  ProfileResponse,
  AsyncThunkDataType<PathParameters, null, "params">
> = createAsyncThunk(
  "companyBackValidate",
  async ({ params, data, config }, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      const response = await api.ValidateProfile(params, null, config);
      return response.data as ProfileResponse;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);
