import { createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../../../api/kyc/api";
import { AsyncThunkActionType } from "../../store";
import { AsyncThunkDataType } from "../../common";
import { SignatoryResponse } from "../slices/signatoriesSlice";
import { Components, Paths } from "../../../api/kyc/generated/client";

type SignatoriesRequest = any; //todo add proper type
//type SignatoriesRequest = Paths.Signatories.RequestBody;
type PathParameters = Paths.Signatories.PathParameters;

export const signatoriesUpdate: AsyncThunkActionType<
  SignatoryResponse,
  AsyncThunkDataType<PathParameters, SignatoriesRequest, "params">
> = createAsyncThunk(
  "signatoriesUpdate",
  async ({ params, data, config }, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await api.Signatories(params, data.signatories === '' ? [] : data.signatories.split(','), config);
      return response.data as SignatoryResponse;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);
