import { Button, Popconfirm } from 'antd'
import { QuestionCircleOutlined } from '@ant-design/icons'
import { FC, ReactNode } from 'react'
import styled from 'styled-components'
import { themeColor } from '../../../styles/themeStyles'

interface BProps {
	btnType: "link" | "text" | "default" | "primary" | "dashed" | undefined,
	htmlType?: "button" | "submit" | "reset" | undefined,
	className?: string,
	ghost?: boolean,
	block?: boolean,
	disabled?: boolean,
	danger?: boolean,
	size?: string,
	children: ReactNode,
	onClick?: (value: any) => void
}

const PopconfirmCustom = styled(Popconfirm)`
    .ant-popover {
        min-width: 350px;
        text-transform: none;
    }
`

const BtnUpper = styled(Button)`
	text-transform: uppercase;
	&.ant-btn:not(.ant-btn-primary) {
		&:active, &:hover, &:focus  {
			color: ${themeColor.primaryColor};
			border-color: ${themeColor.primaryColor};
		}
	}
	&.left {
		margin-right: 30px;
	}
	&.dashed-primary:not([DISABLED]) {
		border-color: ${themeColor.primaryColor}; 
		color: ${themeColor.primaryColor}
	}
	&.ant-btn-primary {
		background-color: ${themeColor.primaryColor};
		border-color: ${themeColor.primaryColor};
		&.ant-btn-background-ghost {
			background-color: #fff;
			color: ${themeColor.primaryColor};
		}
	}
	&[DISABLED].ant-btn-primary:not(.ant-btn-background-ghost) {
		background-color: ${themeColor.primaryLight};
		border-color: ${themeColor.primaryLight};
		color: white
	}
	&[DISABLED]:not(.ant-btn-primary),
	&[DISABLED].ant-btn-background-ghost {
		background-color: transparent!important;
		color: ${themeColor.grayLight};
	}
	&.btn-sm {
		padding: 1px 10px;
		height: auto;
	}
	&.ant-btn-text {
		color: ${themeColor.primaryColor};
		&[DISABLED] {
			color: ${themeColor.grayLight};
		}
	}
	&.btn-info {
		background-color: ${themeColor.grayOpaque};
		border-color: ${themeColor.grayOpaque};
	}
	&.btn-success {
		background-color: #fff;
		border-color: ${themeColor.green};
		color: ${themeColor.green};
	}
	&.block, &.ant-btn-block {
		display: block;
	}
	
`



export const ButtonUpper: FC<BProps> = ({btnType, htmlType, className, disabled, ghost, children, onClick, block}) => {
	return (
		<BtnUpper onClick={onClick} type={btnType} htmlType={htmlType} size={'large'} className={className ? className : ''} ghost={ghost} disabled={disabled} block={block}>{children}</BtnUpper>
	)
}

interface BWProps {
	popupVisible: boolean,
	confirm: (value: any) => void,
	cancel: (value: any) => void,
	handleVisibleChange: (value: any) => void,
	children: ReactNode
}

export const ButtonWrapper: FC<BWProps> = ({
	popupVisible, confirm, cancel, handleVisibleChange, children
}) => {
	return (
		<PopconfirmCustom
          	title="You made some changes to this form. Do You want to continue without saving Your form?"
          	visible={popupVisible}
          	onVisibleChange={handleVisibleChange}
          	onConfirm={confirm}
          	onCancel={cancel}
          	okText="Yes, continue"
          	cancelText="No, cancel"
          	icon={<QuestionCircleOutlined />}
        >
			{children}
		</PopconfirmCustom>
	)
}
