import Title from 'antd/es/typography/Title'
import _ from 'lodash'
import { moment } from '../../kyc-front/ui-elements/FormDate'
import Text from 'antd/es/typography/Text'
import { Row, Col, Statistic, Divider } from 'antd'
import { useSelector } from '../../../hooks/useSelector'
import { dispatch } from '../../../store/store'
import React, { FC, useEffect, useState } from 'react'
import styled from 'styled-components'
import FormUpload from '../../kyc-front/ui-elements/FormUpload'
import { themeColor } from '../../../styles/themeStyles'
import { camelCaseToName } from '../../../utilities/nameParsers'
import KycLayout from '../../kyc-front/kyc-layout/KycLayout'
import { kycApplicationDocGetClear, kycApplicationGetClear } from 'store/kyc-front/slices/kycApplicationSlice'
import { kycApplicationGet } from 'store/kyc-front/asyncThunks/kycApplicationGet'
import { companyProfileBackGet } from 'store/kyc-backend/asyncThunks/companyProfileBackGet'
import { individualProfileBackGet } from 'store/kyc-backend/asyncThunks/individualProfileBackGet'

const SubText = styled(Text)`
	font-size: 14px;
`
const DividerCustom = styled(Divider)`
	margin: 0;
`
const StatisticCustom = styled(Statistic)`
	margin-top: 25px;
	.ant-statistic-title {
		color: ${themeColor.grayDark};
	}
	.ant-statistic-content-value {
		font-size: 16px;
	}
`
const Summary = styled.p`
	span {
		width: 120px;
		display: inline-block;
		&.value {
			width: auto;
			display: inline-block;
		}
	}
`


const KycIntro: FC = () => {
	const profile = useSelector((state) => state.admin.backCompany);
	const individual = useSelector((state) => state.admin.backIndividual);
	const {kycApplication} = useSelector((state) => state.user2.kycApplication)
	const values = kycApplication.values;
	const envs = useSelector((state) => state.general.envs)
	const [detailsLoading, setDetailsLoading] = useState(false);
	//@ts-ignore
  	const profileDetails = profile?.profile;
  	const profileDetailsIndividual = individual?.individual
  	//@ts-ignore
  	const status = kycApplication.status;
  	const profileArchived = envs.isBusiness && profileDetails.profile.archivedAt || envs.isPrivate && profileDetailsIndividual.archivedAt ? true : false
  	useEffect(() => {
  		if(envs.accountType === '') {
  			setDetailsLoading(true);
  		} else {
  			getDetails();
  		}
    }, []);
    useEffect(() => {
    	if(detailsLoading && envs.accountType !== '') {
    		getDetails();
    	}
    }, [envs.accountType]);
    const getDetails = () => {
		dispatch(kycApplicationGet({params: {id: envs.profileId}}))
		dispatch(companyProfileBackGet({params: {companyId: envs.profileId}}))
		dispatch(individualProfileBackGet({params: {individualId: envs.profileId}}));
    }
	return (
		<KycLayout nav={{next: "/step1", step: 0, nextName: "Review KYC", nextButton: "primary", status: status, navSubpage: 'kyc'}} archive={!profileArchived} restore={profileArchived}>
			<Row gutter={30}>
				<Col span={24} style={{'marginBottom': '20px', 'marginTop': '-35px'}}>
					<Text style={{'fontSize': '25px', 'fontWeight':'bold'}}>KYC Status:
						<Text type={profileArchived ? "secondary" : status === 'SUBMITTED' || status === 'ACCEPTED' ? "success" : status === 'NEW' ? "secondary" : "secondary"}><b> {profileArchived ? 'Archived' : status === 'CLARIFICATION_NEEDED' ? 'Clarification needed' : status}</b></Text>
					</Text>
				</Col>
				<DividerCustom />
				{envs.isBusiness ? (
					<Col span={12}>
						<Title level={4} style={{'marginBottom': '20px', 'marginTop': '15px'}}>Company Info</Title>
						<Summary><span>Company:</span> <b>{values.name}</b></Summary>
						<Summary><span>Country:</span> <b>{values.registeredAddress?.country}</b></Summary>
						<Summary><span>Website:</span> <b>{values?.website}</b></Summary>
					</Col>
				) : (
					<Col span={12}>
						<Title level={4} style={{'marginBottom': '20px', 'marginTop': '15px'}}>User Info</Title>
						<Summary><span>Name:</span> <b>{values.firstName ? values.firstName : 'New'} {values.lastName ? values.lastName : 'Person'}</b></Summary>
						<Summary><span>Email:</span> <b>{values?.email}</b></Summary>
						<Summary><span>Phone:</span> <b>{values?.phone}</b></Summary>
					</Col>
				)}
				{/*
				<Col span={12}>
					<Title level={4} style={{'marginBottom': '20px', 'marginTop': '30px'}}>KYC Details</Title>
					{values?.createdAt ? (
						<Summary>
							<span>Created at:</span>
							<span className='value'><b>{values?.createdAt ? moment(values?.createdAt).format('YYYY-MM-DD HH:mm:ss') : moment(values?.createdAt).format('YYYY-MM-DD HH:mm:ss')}</b></span>
						</Summary>
					) : (<></>)}
		            {values?.approvedAt ? (
		            	<Summary>
		            		<span>Approved at:</span> 
		            		<span className='value'><b>{moment(values?.approvedAt).format('YYYY-MM-DD HH:mm:ss')}</b></span>
		            	</Summary>
		            ) : (<></>)}
		            {profileArchived ? (
		            	<Summary>
		            		<span>Archived at:</span>
		            		<span className='value'><b>{values?.archivedAt ? moment(values?.archivedAt).format('YYYY-MM-DD HH:mm:ss') : moment(values?.archivedAt).format('YYYY-MM-DD HH:mm:ss')}</b></span>
		            	</Summary>
					) : (<></>)}
			            
	            </Col>
				*/}
	        </Row>
	        {/*<DividerCustom />*/}
	        {/*}
            <Row style={{'marginBottom': '-40px'}}>
            	<Col span={24}>
            		<Title level={4} style={{'marginBottom': '20px'}}>Latest changes</Title>
			        <Timeline mode="left">
					    <Timeline.Item>Profile created 2022-09-01 18:23:55</Timeline.Item>
					    <Timeline.Item>Profile Submitted for review 2022-09-01 22:21:54</Timeline.Item>
					    <Timeline.Item dot={<ExclamationCircleOutlined style={{ fontSize: '16px', color: 'orange' }} />}>
					      Changes made to KYC 2022-09-02 13:21:33
					    </Timeline.Item>
					    <Timeline.Item>Profile Submitted for review 2022-09-01 22:21:54</Timeline.Item>
					  </Timeline>
            	</Col>
            </Row>*/}
			{envs.isBusiness &&
				<>
					<DividerCustom style={{marginBottom: 15}} />
					<Row gutter={30}>
						<Col span={24}><Title level={4}>Company documents</Title></Col>
						<Col span={24}>
							<FormUpload name='All documents' disabled={true} /*docs={profile.companyAllDocuments}*/ docType='any' personId={envs.profileId} type='ap' /*docsGet={companyAllDocumentsGet} docsGetParams={{companyId: envs.profileId}}*/ />
						</Col>
					</Row>
					<DividerCustom style={{marginBottom: 15}} />
					{!_.isEmpty(values) &&
						<Row gutter={30}>
							<Col span={24}><Title level={4}>Contact persons</Title></Col>
							{values && values.authorizedPersons && values.authorizedPersons.map((person: any) => <ContactModule persons={person} />)}
						</Row>	
					}
				</>
			}	
			{envs.isPrivate &&
				<>
					<DividerCustom style={{marginBottom: 15}} />
					<Row gutter={30}>
						<Col span={24}><Title level={4}>Individual documents</Title></Col>
						<Col span={24}>
							<FormUpload name='All documents' disabled={true} /*docs={individualDocs.individualDocs}*/ docType='any' personId={envs.profileId} type='ap' /*docsGet={individualBackDocsGet} docsGetParams={{individualId: envs.profileId}}*/ />
						</Col>
					</Row>
				</>
			}		
		</KycLayout>
			 
	)
}

const ContactModule = (person: any) => {
	let personEl = person.persons;
	if(personEl.archivedAt || personEl.referencedAuthorizedPerson) {
		return <></>
	}
	let fullName = personEl.name;
	let firstName = personEl.firstName ? personEl.firstName : 'New'
	let lastName = personEl.lastName ? personEl.lastName : 'Person'
	return (
		<Col span={8}>
			<StatisticCustom title={personEl.beneficialOwnerId ? 'UBO' : personEl.roleType ? personEl.roleType === 'OtherIndividual' ? 'Authorized Person' : personEl.roleType : 'Contact Person'} value={fullName ? fullName : firstName + ' ' + lastName} />
			<br />
			{personEl.email && <><SubText>{personEl.email}</SubText><br /></>}
			{personEl.phone && <><SubText>{personEl.phone}</SubText><br /></>}
		</Col>
	)
}

export default KycIntro
