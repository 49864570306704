export interface Step {
    step: number;
    name: string;
    link: string;
    ref?: string;
    refId?: string;
    fields: string[];
    substep?: number;
    hasSubsteps?: boolean;
    steps?: Step[];
    type?: string;
}

const CompanySchema = {
    "companyId": "32309849-8de3-44f8-9862-22dbe5c0de86",
    "name": "HellOCompany",
    "legalForm": "oo",
    "registeredAddress": {
        "id": "6d94aca9-197c-4821-921e-56ee168d21f1",
        "address": "pp",
        "postcode": "55555",
        "city": "London",
        "country": "GB",
        "createdAt": "2024-04-26T08:05:54.174396"
    },
    "headOfficeAddress": {
        "id": "78a14bad-fa0d-4310-bc72-369413ef668c",
        "address": "",
        "postcode": "",
        "city": "",
        "country": "",
        "createdAt": "2024-04-26T08:05:54.187141"
    },
    "dateOfIncorporation": "2024-06-01",
    "registrationNumber": "123",
    "placeOfIncorporation": "London",
    "phone": "",
    "fax": "",
    "createdAt": "2024-04-26T06:37:31.6782",
    "reviewStatus": "SubmittedForReview",
    "businessRelation": {
        "periods": [],
        "isActive": false
    },
    "id": "dd7e6279-2e10-4516-b7fc-4a860bb91436",
    "website": "www.website.com",
    "activitiesAndBusinessModel": "nature",
    "geographicalArea": "RWW",
    "annualTurnoverEUR": "10,000,000",
    "estimatedAmountOfBTC": "",
    "estimatedAmountOfEUR": "100,000",
    "banks": "",
    "fundingOfBusinessDescription": "yup",
    "fundingOfBusinessType": "OtherFundingOfBusinessType",
    "businessLicence": "no",
    "complianceProgram": "",
    "numberOfEmployees": 1
}

const APSchema = {
    "authorizedPersonId": "1b88f415-86b5-45c4-8c49-a4ed41c50866",
    "companyId": "32309849-8de3-44f8-9862-22dbe5c0de86",
    "firstName": "Henry",
    "lastName": "Oh",
    "residentialAddress": {
        "id": "6730cf4f-166e-4a1a-a2d6-74d42d8685d7",
        "address": "some street 123",
        "postcode": "55555",
        "city": "London",
        "country": "GB",
        "createdAt": "2024-04-26T08:06:12.910318"
    },
    "identityDocument": {
        "id": "cb0a8c7b-d432-4859-9875-186732ee33ef",
        "number": "123",
        "placeOfIssue": "London",
        "dateOfIssue": "2024-06-01",
        "dateOfExpiry": "2027-06-01",
        "nationality": "GB",
        "kind": "PassportKind",
        "createdAt": "2024-04-26T08:06:12.917097"
    },
    "nationality": "GB",
    "placeOfBirth": "London",
    "dateOfBirth": "1980-01-05",
    "phone": "123",
    "email": "dir@hello.co",
    "occupation": "Director",
    "roleType": "Director",
    "signatory": true,
    "createdAt": "2024-04-26T08:06:04.425084"
}

const UBOSchema = {
    "beneficialOwnerId": "519886b6-5b2d-439e-9019-41ae8fdaf75f",
    "companyId": "32309849-8de3-44f8-9862-22dbe5c0de86",
    "firstName": "Henry",
    "lastName": "Oh",
    "residentialAddress": {
        "id": "46a96c50-4f57-45e8-b227-3b0ccb63c1b6",
        "address": "some street 123",
        "postcode": "55555",
        "city": "London",
        "country": "GB",
        "createdAt": "2024-06-03T09:21:55.293655"
    },
    "nationality": "GB",
    "identityDocument": {
        "id": "219bea04-5e0d-4e19-a906-51d5bcc34a49",
        "number": "123",
        "placeOfIssue": "London",
        "dateOfIssue": "2024-06-01",
        "dateOfExpiry": "2027-06-01",
        "nationality": "GB",
        "kind": "PassportKind",
        "createdAt": "2024-06-03T09:21:55.300969"
    },
    "placeOfBirth": "London",
    "dateOfBirth": "1980-01-05",
    "phone": "123",
    "email": "dir@hello.co",
    "occupation": "Director",
    "estimatedNetWorth": "5000001",
    "estimatedAmountOfEUR": "x",
    "sourceOfWealthBusinessActivities": true,
    "sourceOfWealthProfessionalOccupation": false,
    "sourceOfWealthInheritance": false,
    "sourceOfWealthOther": false,
    "sourceOfWealthDescription": "yes",
    "isPoliticallyExposedPerson": false,
    "isUSNationality": false,
    "roleType": "HoldsMoreThan25PercentageOfShares",
    "referencedAuthorizedPerson": "1b88f415-86b5-45c4-8c49-a4ed41c50866",
    "createdAt": "2024-06-03T09:21:55.30822"
}

const IndividualSchema = {
    "individualId": "aaecc2d7-6713-49fa-b4e9-15df07293d36",
    "beneficialOwnerId": "1b3700aa-d144-44bb-8652-6322d91c5336",
    "firstName": "Anna",
    "lastName": "Annas",
    "residentialAddress": {
        "id": "06838d4a-0640-4589-b206-3ac04796732a",
        "address": "US",
        "postcode": "22222",
        "city": "Orlando",
        "country": "US",
        "createdAt": "2024-04-26T07:00:10.458726"
    },
    "nationality": "US",
    "identityDocument": {
        "id": "abff25b4-4ec0-41b9-87b0-c901ea86a125",
        "number": "123",
        "placeOfIssue": "123",
        "dateOfIssue": "2024-05-24",
        "dateOfExpiry": "2029-05-26",
        "nationality": "US",
        "kind": "PassportKind",
        "createdAt": "2024-04-26T07:00:10.530515"
    },
    "placeOfBirth": "US",
    "dateOfBirth": "1980-01-05",
    "phone": "",
    "email": "emilia.jendrusik+8888@gmail.com",
    "occupation": "",
    "estimatedNetWorth": "5000001",
    "estimatedAmountOfEUR": "1",
    "sourceOfWealthBusinessActivities": true,
    "sourceOfWealthProfessionalOccupation": false,
    "sourceOfWealthInheritance": false,
    "sourceOfWealthOther": false,
    "sourceOfWealthDescription": "aaa",
    "isPoliticallyExposedPerson": false,
    "isUSNationality": false,
    "roleType": "PrivateIndividual",
    "createdAt": "2024-04-25T11:05:55.47313",
    "reviewStatus": "PartiallyFilled",
    "businessRelation": {
        "periods": [],
        "isActive": false
    }
}

export const IndividualSteps: Step[] = [
    {
        step: 1,
        name: 'Personal details',
        link: '/step1',
        fields: ['firstName', 'lastName', 'dateOfBirth', 'placeOfBirth', 'nationality', 'occupation'],
    },
    {
        step: 2,
        name: 'Contact',
        link: '/step2',
        fields: ['residentialAddress.address', 'residentialAddress.postcode', 'residentialAddress.city', 'residentialAddress.country', 'email', 'phone', 'docs.ProofOfAddress']
    },
    {
        step: 3,
        name: 'Identification',
        link: '/step3',
        fields: ['identityDocument.number', 'identityDocument.placeOfIssue', 'identityDocument.dateOfIssue', 'identityDocument.dateOfExpiry', 'identityDocument.nationality', 'nationality', 'identityDocument.kind', 'docs.PassportFront', 'docs.IDCardFront', 'docs.IDCardBack']
    },
    {
        step: 4,
        name: 'Source of wealth',
        link: '/step4',
        fields: ['sourceOfWealthBusinessActivities', 'sourceOfWealthProfessionalOccupation', 'sourceOfWealthInheritance', 'sourceOfWealthOther', 'sourceOfWealthDescription', 'estimatedNetWorth', 'estimatedAmountOfEUR']
    },
    {
        step: 5,
        name: 'Declarations',
        link: '/step5',
        fields: ['isUSNationality', 'isPoliticallyExposedPerson']
    },
    {
        step: 6,
        name: 'Submit',
        link: '/step6',
        fields: ['correctnessDeclaration']
    }
]

export const CompanySteps: Step[] = [
    {
        step: 1,
        name: 'Company details',
        link: '/step1',
        fields: ['name', 'legalForm', 'dateOfIncorporation', 'registrationNumber', 'placeOfIncorporation', 'website']
    },
    {
        step: 2,
        name: 'Registration address',
        link: '/step2',
        fields: ['registeredAddress.address', 'registeredAddress.postcode', 'registeredAddress.city', 'registeredAddress.country', 'headOfficeAddress.sameAsRegistered', 'headOfficeAddress.headOfficeAddress', 'headOfficeAddress.address', 'headOfficeAddress.postcode', 'headOfficeAddress.city', 'headOfficeAddress.country']
    },
    {
        step: 3,
        name: 'Operations and activities',
        link: '/step3',
        fields: ['geographicalArea', 'numberOfEmployees', 'activitiesAndBusinessModel', 'annualTurnoverEUR', 'businessLicence']
    },
    {
        step: 4,
        name: 'Financials',
        link: '/step4',
        fields: ['estimatedAmountOfEUR', 'fundingOfBusinessType', 'fundingOfBusinessDescription']
    },
    {
        step: 5,
        name: 'Authorized persons',
        type: 'authorizedPersons',
        link: '/step5',
        fields: []
    },
    {
        step: 6,
        name: 'Ultimate Beneficial Owners',
        type: 'beneficialOwners',
        link: '/step6',
        fields: []
    },
    {
        step: 7,
        name: 'Documents',
        link: '/step7',
        fields: ['docs.OtherDocumentKind']
    },
    {
        step: 8,
        name: 'Submit',
        link: '/step8',
        fields: ['isSignatory']
    }
]
export const APSteps: Step[] = [
    {
        step: 1,
        name: 'Personal details',
        link: '/substep1',
        fields: ['firstName', 'lastName', 'roleType', 'dateOfBirth', 'placeOfBirth', 'nationality', 'occupation'],
    },
    {
        step: 2,
        name: 'Contact',
        link: '/substep2',
        fields: ['residentialAddress.address', 'residentialAddress.postcode', 'residentialAddress.city', 'residentialAddress.country', 'email', 'phone', 'docs.ProofOfAddress']
    },
    {
        step: 3,
        name: 'Identification',
        link: '/substep3',
        fields: ['identityDocument.number', 'identityDocument.placeOfIssue', 'identityDocument.dateOfIssue', 'identityDocument.dateOfExpiry', 'identityDocument.nationality', 'identityDocument.kind', 'nationality', 'docs.PassportFront', 'docs.IDCardFront', 'docs.IDCardBack']
    },
    {
        step: 4,
        name: 'Other documents',
        link: '/substep4',
        fields: ['docs.OtherDocumentKind']
    },
]

export const UboSteps: Step[] = [
    {
        step: 1,
        name: 'Personal details',
        link: '/substep1',
        fields: ['firstName', 'lastName', 'dateOfBirth', 'placeOfBirth', 'nationality', 'occupation'],
    },
    {
        step: 2,
        name: 'Contact',
        link: '/substep2',
        fields: ['residentialAddress.address', 'residentialAddress.postcode', 'residentialAddress.city', 'residentialAddress.country', 'email', 'phone', 'docs.ProofOfAddress']
    },
    {
        step: 3,
        name: 'Identification',
        link: '/substep3',
        fields: ['identityDocument.number', 'identityDocument.placeOfIssue', 'identityDocument.dateOfIssue', 'identityDocument.dateOfExpiry', 'identityDocument.nationality', 'nationality', 'identityDocument.kind', 'docs.PassportFront', 'docs.IDCardFront', 'docs.IDCardBack']
    },
    {
        step: 4,
        name: 'Other documents',
        link: '/substep4',
        fields: ['docs.OtherDocumentKind']
    },
    {
        step: 5,
        name: 'Source of wealth',
        link: '/substep5',
        fields: ['roleType', 'sourceOfWealthBusinessActivities', 'sourceOfWealthProfessionalOccupation', 'sourceOfWealthInheritance', 'sourceOfWealthOther', 'sourceOfWealthDescription', 'estimatedNetWorth']
    },
    {
        step: 6,
        name: 'Declarations',
        link: '/substep6',
        fields: ['isUSNationality', 'isPoliticallyExposedPerson']
    }
]

