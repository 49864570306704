import { FC } from 'react'
import { Row, Col, Space } from 'antd'
import Text from 'antd/es/typography/Text'
import styled from 'styled-components'
import { themeColor } from 'styles/themeStyles'

const Signature = styled.div`
    border: 1px solid ${themeColor.grayLight};
    padding: 5px 10px;
    height: 100px;
    min-width: 200px;
    margin-top: 15px;
    margin-right: 15px;
    text-align: center;
`

interface KProps {
    person: any,
    printRender: boolean
}

const KycCorrectnessDeclarationForm: FC<KProps> = ({person, printRender}) => {
    return (
        <Space direction='vertical' size={'large'}>
            <div style={!printRender ? {'marginBottom': '15px'} : {}}>
                <Text><b>1. Correctness and changes</b></Text><br/>
                <Text>I hereby declare that the details furnished above are true and correct to the best of my knowledge and belief and <b>I undertake to inform you of any changes therein, immediately by sending an email to KYC@bitclear.li</b>. In case any of the above information is found to be false or untrue or misleading or misrepresenting, I am aware that I may be held liable for it.</Text>
            </div>
            {printRender && (
                <>
                    <br />
                    <Text><b>Customer's signature:</b></Text>
                    <Row>
                        <Col><Signature><span>{person.firstName} {person.lastName}</span></Signature></Col>
                    </Row>
                </>
            )}
        </Space>
        )

}

export default KycCorrectnessDeclarationForm